.btn-top{
position: fixed;
display: flex;
align-items: center;
justify-content: center;
right: 1.25rem;
width: 2.75rem;
height: 2.75rem;
transition: bottom 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55),opacity .3s,background-color .25s ease-in-out;
border-radius: 50%;
background-color: rgba(19,16,34,.25);
color: #fff;
text-decoration: none;
z-index: 1030;

}