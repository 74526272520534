/* Hero text animation*/
@keyframes hero-fade {
    0%{
        opacity: 0;
     }
     100% {
        opacity: 1;
     }

  }
.hero-animation-fade{
    animation: hero-fade 4s infinite alternate;
    -webkit-animation: hero-fade 4s infinite alternate;

    
}


.hero-animation-delay-1 {
    animation-delay: 1s;
    opacity: 0;
}

.hero-animation-delay-2 {
    animation-delay: 2s;
    opacity: 0;
}

.hero-animation-delay-3 {
    animation-delay: 3s;
    opacity: 0;
}




/* Hero circle animation*/
@keyframes hero-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

.hero-animation-spin{
    animation: hero-spin 35s linear infinite;
}



.bg-dark-custom{
    background-color: #070A11;
}

.text-muted-custom{
    color: #3E4053;
  
}

.bg-secondary-custom{
    background-color: #F6FAFD;
}

.bg-primary-custom{
    /* background-image: linear-gradient(to bottom, rgba(224,42,56,1), rgba(247,143,12,1)); */
    background-color: rgba(224,42,56,1);
}




